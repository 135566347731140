import React from 'react';

const AccessibilityAlert = ({ message }) => {
  return (
    <div id="srmessage" role="alert" style={{ display: 'none' }}>
      {message}
    </div>
  );
};

export default AccessibilityAlert;
