import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../../common/components/runtime-context';
import classNames from 'classnames';

import {
  SECTIONS,
  POST_EXCERPT_MAX_LENGTH,
  isLayoutNameOneOfProGallery,
  isLayoutNameTextOnImage,
  LAYOUT_NAME_SIDE_BY_SIDE,
  isLayoutNameMobile,
  getPostCover,
} from '@wix/communities-blog-client-common';

import PostListItemHeader from '../../../common/components/post-list-item-header';
import PostTitle from '../../../common/components/post-title';
import ContentExcerpt from '../../../common/components/content-excerpt';
import PostFooter from '../../../common/components/post-footer';
import PostListItemCover from '../../../common/components/post-list-item-cover';
import Link from '../../../common/components/link/internal-link';
import ResponsiveContentExcerpt from '../../../common/components/responsive-content-excerpt';
import { getLineCounts, getFeedColorClassName } from '../../../common/services/layout-config';
import { HorizontalSeparatorForPostCard } from '../../../common/components/separator';
import withFeedBorderWidth from '../../../common/hoc/with-feed-border-width';
import withFeedMetadataSettings from '../../../common/hoc/with-feed-metadata-settings';
import withFontClassName from '../../../common/hoc/with-font-class-name';
import withIsFeedDesignEnabled from '../../../common/hoc/with-is-feed-design-enabled';
import withPostFontSize from '../../../common/hoc/with-post-font-size';
import withPermissions from '../../../common/hoc/with-permissions';
import { getSection } from '../../../common/selectors/section-selectors';
import { getPostCoverImageSrc } from '../../../common/selectors/post-selectors';
import { getPostActions } from '../../../common/services/post-actions';
import { getIsMemberAreaInstalled } from '../../../common/store/communities-context/communities-context-selectors';
import { getIsMoreButtonEnabled } from '../../../common/selectors/app-settings-selectors';
import styles from '../../../common/styles/post-list-item.scss';
import withMediaHosts from '../../../common/hoc/with-media-hosts';

export const PostListItem = ({
  type,
  post,
  onLikeClick,
  itemConfig,
  borderWidth,
  titleFontClassName,
  contentFontClassName,
  contentFontClassNameWithStyle,
  postTitleFontSize,
  postDescriptionFontSize,
  postMetadataFontSize,
  isMetadataFooterVisible,
  isMetadataHeaderVisible,
  showMoreButton,
  showPostDescription,
  applyFeedDesign,
  getPostClassName,
  index,
  isMoreButtonEnabled,
  imageHost,
  videoHost,
  can,
  navigateProGalleryWithinPostPage,
}) => {
  const { shouldRender: withCover } = getPostCover(post, imageHost, videoHost);
  const lineCounts = getLineCounts(itemConfig, withCover);
  const postLink = `/${post.slug}`;
  const autoFit = type === LAYOUT_NAME_SIDE_BY_SIDE && withCover;

  const isProGallery = isLayoutNameOneOfProGallery(type);

  const isMobileLayout = isLayoutNameMobile(type);
  const withoutFooter = !isMetadataFooterVisible;
  const withoutHeaderMetadata = !isMetadataHeaderVisible;
  const withoutDescription = !showPostDescription;
  const useResponsive = type === LAYOUT_NAME_SIDE_BY_SIDE;
  const containerClassName = classNames(
    styles.container,
    isProGallery && styles.proGallery,
    styles[type],
    withCover && styles.withCover,
    withoutFooter && styles.withoutFooter,
    contentFontClassName,
    'blog-text-color',
    'blog-card-background-color',
    'blog-card-border-color',
    'post-list-item',
    !applyFeedDesign && !isLayoutNameTextOnImage(type) && 'blog-hover-container',
    !isLayoutNameTextOnImage(type) && getPostClassName('hover-container'),
    getPostClassName('border-color', 'post-container', getFeedColorClassName(type, 'background-color')),
    isMobileLayout && index > 0 && styles.newPadding,
  );

  const contentWrapperClassName = classNames(
    styles.contentWrapper,
    styles[type],
    !applyFeedDesign && isLayoutNameTextOnImage(type) && styles.withBackground,
    withCover && styles.withCover,
    withoutFooter && styles.withoutFooter,
    'post-list-item-wrapper',
    isLayoutNameTextOnImage(type) && getPostClassName('overlay-background-color'),
    getPostClassName(
      'description-font',
      getFeedColorClassName(type, 'description-color'),
      getFeedColorClassName(type, 'description-fill'),
    ),
    isMobileLayout ? styles.newPadding : styles.boxShadow,
  );

  const linkContainerClassName = classNames(
    isMobileLayout ? styles.mobileWrapper : styles.textWrapper,
    getPostClassName('link-hashtag-hover-color'),
    styles[type],
  );
  const linkClassName = classNames(
    styles.titleWrapper,
    isProGallery && styles.proGallery,
    styles[type],
    withCover && styles.withCover,
    withoutHeaderMetadata && styles.withoutHeaderMetadata,
    post.isPinned && styles.withIcons,
    showMoreButton && isMoreButtonEnabled ? styles.withShowMoreButton : styles.withoutShowMoreButton,
  );
  const titleContainerClassName = classNames(
    styles.title,
    titleFontClassName,
    styles[type],
    withCover && styles.withCover,
    withoutFooter && styles.withoutFooter,
    withoutHeaderMetadata && styles.withoutHeaderMetadata,
    withoutDescription && withoutFooter && styles.withoutBottomMargin,
    withoutDescription && styles.withoutDescription,
    post.isPinned && styles.withIcons,
    showMoreButton && isMoreButtonEnabled ? styles.withShowMoreButton : styles.withoutShowMoreButton,
  );
  const titleClassName = classNames(
    getPostClassName('title-font', getFeedColorClassName(type, 'title-color')),
    useResponsive && styles.responsiveTitle,
  );
  const contentClassName = classNames(
    styles.content,
    styles[type],
    withoutFooter && styles.withoutFooter,
    contentFontClassNameWithStyle,
    withoutHeaderMetadata && styles.withoutHeaderMetadata,
    getPostClassName('description-style-font'),
    styles.excerptLink,
  );
  const headerClassName = classNames(contentFontClassName, getPostClassName('description-font'));
  const footerClassName = classNames(
    getPostClassName('description-font', getFeedColorClassName(type, 'description-color')),
  );
  const titleStyle = isMobileLayout ? { fontSize: postTitleFontSize } : {};
  const contentStyle = !applyFeedDesign || isMobileLayout ? { fontSize: postDescriptionFontSize } : {};

  const renderCover = () => {
    const coverProps = {
      post,
      postLink,
      type,
      canPlayVideo: true,
      isPublic: can('share', 'post', post),
      videoClassName: classNames(styles.videoEmbed),
      imageClassName: classNames(styles.image),
      useAlternateDimesions: withoutFooter,
      ...(useResponsive
        ? {
            height: 518,
            width: 690,
            withoutContainerStyle: true,
          }
        : {}),
    };

    const cover = <PostListItemCover {...coverProps} />;

    return useResponsive ? (
      <div className={styles.coverContainer}>
        <div className={styles.coverWrapper}>{cover}</div>
      </div>
    ) : (
      cover
    );
  };

  const containerStyle = isProGallery
    ? {
        ...(getPostCoverImageSrc(post) && { background: 'transparent' }),
        borderWidth: 0,
      }
    : {
        borderWidth,
      };

  return (
    <article className={containerClassName} tabIndex="0" style={containerStyle} data-hook="post-list-item">
      {withCover && renderCover()}
      <div className={contentWrapperClassName}>
        <PostListItemHeader
          className={headerClassName}
          post={post}
          showMoreButton={isMoreButtonEnabled}
          showProfileImage={!isLayoutNameTextOnImage(type)}
          style={{ fontSize: postMetadataFontSize }}
          type={type}
        />
        <div className={linkContainerClassName} style={{ display: 'flex-inline' }}>
          <Link fullRoute={post.link} to={postLink} className={linkClassName} addHoverClasses={false}>
            <div
              style={{ fontSize: postTitleFontSize }}
              className={titleContainerClassName}
              data-hook="post-list-item__title"
            >
              <PostTitle
                type={type}
                title={post.title}
                style={titleStyle}
                className={titleClassName}
                {...(useResponsive ? {} : { lineCount: lineCounts.title })}
              />
            </div>
          </Link>
          {showPostDescription && lineCounts.description ? (
            <div
              style={contentStyle}
              className={contentClassName}
              data-hook="post-description"
              onClick={() => navigateProGalleryWithinPostPage(post.slug)}
            >
              {useResponsive ? (
                <ResponsiveContentExcerpt post={post} />
              ) : (
                <ContentExcerpt
                  type={type}
                  content={post.content}
                  excerpt={post.excerpt}
                  lineCount={lineCounts.description}
                  autoFit={autoFit}
                  maxLength={POST_EXCERPT_MAX_LENGTH}
                />
              )}
            </div>
          ) : null}
        </div>
        {isMetadataFooterVisible && (
          <div className={classNames(styles.footer, styles[type], !withCover && styles.withoutCover)}>
            {!isMobileLayout && (
              <HorizontalSeparatorForPostCard className={classNames(styles.separator, styles[type])} />
            )}
            <div style={{ fontSize: postMetadataFontSize }}>
              <PostFooter
                post={post}
                onLikeClick={onLikeClick}
                displayIcons={itemConfig.displayFooterIcons}
                className={footerClassName}
                commentClassName={classNames(getPostClassName('link-hashtag-hover-color'))}
              />
            </div>
          </div>
        )}
        {isMobileLayout && (
          <HorizontalSeparatorForPostCard
            className={classNames(
              styles.separator,
              styles.mobileSeparator,
              styles[type],
              isMetadataFooterVisible && styles.withMetadata,
            )}
          />
        )}
      </div>
    </article>
  );
};

PostListItem.propTypes = {
  onLikeClick: PropTypes.func.isRequired,
  getPostClassName: PropTypes.func.isRequired,
  post: PropTypes.object,
  type: PropTypes.string.isRequired,
  itemConfig: PropTypes.object.isRequired,
  borderWidth: PropTypes.number,
  titleFontClassName: PropTypes.string.isRequired,
  contentFontClassName: PropTypes.string.isRequired,
  contentFontClassNameWithStyle: PropTypes.string.isRequired,
  postTitleFontSize: PropTypes.number.isRequired,
  postDescriptionFontSize: PropTypes.number.isRequired,
  postMetadataFontSize: PropTypes.number.isRequired,
  isMetadataFooterVisible: PropTypes.bool,
  isMetadataHeaderVisible: PropTypes.bool,
  showMoreButton: PropTypes.bool,
  showPostDescription: PropTypes.bool,
  applyFeedDesign: PropTypes.bool.isRequired,
  section: PropTypes.oneOf(SECTIONS),
  index: PropTypes.number,
  isMoreButtonEnabled: PropTypes.bool,
  navigateProGalleryWithinPostPage: PropTypes.func.isRequired,
};

PostListItem.defaultProps = {
  isMetadataFooterVisible: true,
  isMetadataHeaderVisible: true,
};

const mapRuntimeToProps = (state, { post, canSee }, actions) => {
  const postActions = getPostActions({
    post,
    canSee,
    enableShare: true,
    enableSubscribe: getIsMemberAreaInstalled(state),
  });
  const showMoreButton = Boolean(postActions.length);

  return {
    showMoreButton,
    section: getSection(state),
    isMoreButtonEnabled: getIsMoreButtonEnabled(state, showMoreButton),
    navigateProGalleryWithinPostPage: actions.navigateProGalleryWithinPostPage,
  };
};

// prettier-ignore
export default flowRight(
  withPermissions,
  withFontClassName,
  withPostFontSize,
  withFeedMetadataSettings,
  withIsFeedDesignEnabled,
  withFeedBorderWidth,
  withMediaHosts,
  connect(mapRuntimeToProps),
)(PostListItem);
